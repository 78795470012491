import React, {useState} from 'react'
import logo from './logo.svg';
import './App.css';
import Form from './Forms.js';
import Arrow from './assets/arrow.svg'
import Logo from './logo.png'
import MapPing from './mapPin.png'
import Calendar from './calendar.png'
import Flyer from './flyer2.png'

function App() {
  const [startScreen, setStartScreen] = useState(true)
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isFriendlistModalOpen, setIsFriendlistModalOpen] = useState(false)

  //form Logic
  const [submit, setSubmit] = useState(false)
  const [vornameInput, setVornameInput] = useState("");
  const [nachnameInput, setNachnameInput] = useState("");
  const [instaInput, setInstaInput] = useState("");
  const [isInterestedInTableReservation, setIsInterestedInTableReservation] = useState(false)
  const [tableReservationParamter, setTableReservationParamter] = useState("Nein")

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);

    //let url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSewQli5dBi0g1S8Ve-Bz6Xr7XP4W_27mBoVJwmftpsWTkFTVQ/formResponse?entry.2037917220=${formData["entry.2037917220"]}&entry.1146839265=${formData["entry.1146839265"]}&entry.1451219623=${formData["entry.1451219623"]}`;
    //let url2 = `https://docs.google.com/forms/u/1/d/e/1FAIpQLScyLcS92BnqiOf7iKlU3xnl_SmC6Ie6JKrj6pKext8wfqcFWQ/formResponse?entry.741581054=${nameInput}&entry.151046026=${instaInput}&entry.996683526=${tableReservationParamter}`
    let url2 = `https://docs.google.com/forms/u/1/d/e/1FAIpQLSeZTQ1LxRbqdeO7FonlTbh9D9vkioy4KSQokelELQSVCzQDKw/formResponse?entry.741581054=${vornameInput}&entry.1225530893=${nachnameInput}&entry.151046026=${instaInput}&entry.996683526=${tableReservationParamter}`;


    const res = await fetch(url2, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }


  return (
      <div className='app'>
        <div className="full-screen-nav animated" style={{bottom: isNavOpen? 0 : '100vh'}}>
          <hr className={isNavOpen ? 'nav-in-0' : 'op-0'} style={{ marginTop: '4rem'}} />
          <h1
            style={{marginTop: '2rem'}}
            className={isNavOpen ? 'nav-in-0' : 'op-0'}
            onClick={() => {setIsNavOpen(false);setIsFriendlistModalOpen(false)}}
          >
            NEXT
          </h1>
          <a
          href="https://api.whatsapp.com/send?phone=491755556578&text=Hey,%20ich%20bin%20an%20einer%20Tischreservierung%20interssiert!%E2%9D%A4%EF%B8%8F%E2%80%8D%F0%9F%94%A5"
          >
            <h1
              className={isNavOpen ? 'nav-in-1' : 'op-0'}>
              BOOK A TABLE
            </h1>
          </a>
          <div className={isNavOpen ? 'location-info nav-in-4' : 'op-0'}>
              <div>
                <div>
                  <img src={MapPing} alt="map-pin" />
                </div>
                <div>
                  <img src={Calendar} alt="calendar" />
                </div>
              </div>
              <div>
                <div>
                  <p>MYHOUSE</p>
                  <p>Gothaer Str. 11</p>
                  <p>01097 Dresden</p>
                </div>  
                <div>
                  <p>Fr, 16.02.24</p>
                  <p>Ab 22 UHR</p>
                </div>
              </div>
          </div>
        </div>
        <div className="navbar animated" style={{height: startScreen ? '100%' : '4rem'}}>
          <h1 
            style={{ fontSize: startScreen ? '36px' : '0px', opacity: startScreen? 1:0}}
            className="animated"
          >
            Welcome to Heat
          </h1>
          <img
            style={{ 
              height: startScreen ? '300px' : '4rem',
              animation: startScreen ? 'float 4s ease-in-out infinite' : ''
            }}
            src={Logo}
            className="animated"
            alt='Logo'
            onClick={() => {setIsNavOpen(false);setIsFriendlistModalOpen(false)}}
          />
            <div
              style={{ fontSize: startScreen ? '36px' : '0px', opacity: startScreen? 1:0}}
              onClick={() => setStartScreen(!startScreen)}
              className="animated button"
            >
              <span>
              Enter
              </span>
            </div>
          {!startScreen && <div className="hamburger-icon-wrapper" onClick={() => setIsNavOpen(!isNavOpen)}>
            <div className={['animated', isNavOpen ? 'one': ''].join(" ")} />
            <div className={['animated', isNavOpen ? 'two': ''].join(" ")} />
          </div>}
        </div>
        <h1 style={{marginTop: '6rem'}}>HEAT VOLUME 16 | Fr., 16.02.24</h1>
        <div className="event-card"  onClick={() => false && setIsFriendlistModalOpen(true)}>
          {true && <img 
            src={Flyer}
            alt="flyer"
          />}
          <div className="event-card-content" style={{padding: '0rem 2rem'}}>
            <p 
              className='button button--filleds button--disabled'
              style={{
                width: 'auto',
                padding: '.4rem 2rem',
                justifySelf: 'center',
                fontSize: '1.618rem',
                marginBottom: '1.5rem',
              }}
            >
              <span>
                {false && "ENTER THE FRIENDLIST"}
                {true && "DIE FRIENDLIST IST VOLL"}
              </span>
            </p>
            {true && <p style={{ opacity: .8, fontSize: '1.2rem'}}>Die Abendkasse öffnet 22 UHR</p>}
          </div>
        </div>
        <div className="event-desc">
            <br />
            Welcome to a new era of heat!❤️‍🔥<br />
            <br />
            🆕Whats new:<br />
            •⁠  ⁠Kein fester Dresscode mehr! Wir wollen Heat etwas lockerer und offener gestalten (zieht an, wie ihr euch wohlfühlt !)<br />
            •⁠  ⁠Neue Musikrichtungen! Weniger Afro & mehr Hiphop +  Experimental - Lasst euch überraschen ;)<br />
            •⁠  ⁠neues Lichtkonzept<br />
            •⁠  ⁠neue Getränkespecials<br />
            •⁠  ⁠23Uhr Einlassstart<br />
            •⁠  ⁠22-23Uhr Girlshour (only Girls und halber Eintritt)<br />
            •⁠  ⁠mehr kleinere Überaschungen vor Ort<br />
            •⁠  ⁠Keine Friendlist bestätigung mehr. Wer sich einträgt, steht drauf<br />
            •⁠  ⁠Einlass ohne Friendlist-Entragung 14€, mit Eintragung 12€ <br />
            •⁠  ⁠schnellerer Einlass und Gardarobe <br />
            •⁠  ⁠Aufladen der Heat Karte auch im oberen Bereich an der Bar möglich (auch mit Bargeld)<br />
            <br /><br />
              ▪️Date: 16.02<br />
              ▪️Time: 23:00 entry <br />
              ▪️Location: My House Dresden- Erfurterstr.12/ 01097 Dresden<br />
              ▪️Indoor Floor <br />
              🎶 HipHop | Experimental<br />
              DJs: 
              Luccatree<br />
              YungViral<br />
              + special <br />
              <br />
              🍸Special Heat-Drink all night!<br />
              <br />
              Tickets at the venue!<br />
        </div>
        <div
          className='friendlist-modal animated'
          style={{transform: isFriendlistModalOpen ? 'translateY(0%)' : 'translateY(100%)'}}
        >
          <h1 style={{marginTop: '5.5rem'}}>Enter the Friendlist</h1>
          {submit ? 
          <p>
            <span style={{ fontSize: '3rem'}}>
            ❤️‍🔥
            </span>
            <br /><br />
            <b>Eintragung erfolgreich!</b>
            <br />
             Dein Name wird am Einlass für günstigeren Eintritt hinterlegt sein. Zudem kannst du durch den "Friendlist"- Eingang rein. Wir freuen uns auf dich!
          </p>
          :
          <>
          <p>vergünstigter Einlass + FastLane | gilt nur bis 0:30 Uhr</p>
          <p>Fr., 16.02 | MYHOUSE | P18!</p>
          <p style={{color: '#777'}}>Trag dich hier in die FRIENDLIST ein und sag dann am Einlass einfach deinen Namen.</p>
          
          <form onSubmit={handleSubmit} target="_self">
          <fieldset>
              <label htmlFor="entry.2037917220">Vorname:</label>
              <input
                required
                type="text"
                name="entry.2037917220"
                onChange={e => setVornameInput(e.target.value)}
                value={vornameInput}
                autoComplete={false}
              />
            </fieldset>
            <fieldset>
              <label htmlFor="entry.2037917220">Nachname:</label>
              <input
                required
                type="text"
                name="entry.2037917220"
                onChange={e => setNachnameInput(e.target.value)}
                value={nachnameInput}
                autoComplete={false}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.1146839265">Instagram- Name:</label>
              <input
                required
                type="text"
                onChange={e => setInstaInput(e.target.value)}
                value={instaInput}
                autoComplete={false}
              />
            </fieldset>
            <a
          href="https://api.whatsapp.com/send?phone=491755556578&text=Hey,%20ich%20bin%20an%20einer%20Tischreservierung%20interssiert!%E2%9D%A4%EF%B8%8F%E2%80%8D%F0%9F%94%A5"
          style={{
            padding: '.5rem 0',
            fontSize: '1.1rem',
            textDecoration: 'none',
            color: '#FF2A00',
            margin: '1rem auto 1rem auto'
           }}
          >Tischreservierung? Klicke hier!</a>
            <button
              type="submit"
              disabled={instaInput.length < 2 || vornameInput.length < 2 || nachnameInput.length < 2}
              className="button button--filled" 
              style={{
                width: '80%',
                padding: '.5rem 0',
                fontSize: '1.8rem',
                margin: '0rem auto 5rem auto'
               }}
            >
              Absenden
            </button>
          </form></>
        }
        </div>
      </div>
  );
}

export default App;
